import React, { useState } from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { visa, masterCard, appleIcon, gpayIcon } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import { scrollTop } from "../../utils/reuse";
import { useToast } from '../../context/ToastContext';
import getAssets from "../../assets/data/brandingAssets.json";

const Footer = () => {
  const { labels, general, globalConfig, header  } = useToast();
  const [year] = useState(new Date().getFullYear());
  const { i18n } = useTranslation();

  const  isJsonString = (str)  => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const order = isJsonString(header?.HEAD0001) ? JSON.parse(header?.HEAD0001): [];

  
  return (
    <>
      <footer>
        <div className='footer-info grid grid-nogutter'>
          <div className='col-12 lg:col-3 md:col-6 footer-logo'>
            <span className='social-span'>{labels.LBL0063}</span>
            <ul>
              <li><Link to={getAssets.footer.socialMedia.facebook} target='_blank' rel='noreferrer'className='social-image facebook'></Link></li>
              <li><Link to={getAssets.footer.socialMedia.twitter} target='_blank' rel='noreferrer'className='social-image twitter'></Link></li>
              <li><Link to={getAssets.footer.socialMedia.youtube} target='_blank' rel='noreferrer'className='social-image youtube'></Link></li>
            </ul>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0214}</h2>
            <div className='footer-links'>
              {order.includes('MENU001') && <Link to={"/"} onClick={scrollTop}>{header?.MENU001 ? header?.MENU001 : labels.LBL0216}</Link>}
              {order.includes('MENU002') && <Link to={`/${i18n.language}/country-page`}>{header?.MENU002 ? header?.MENU002 : labels.LBL0218}</Link>}
              {order.includes('MENU004') && <Link to="/affiliate">{header?.MENU004 ? header?.MENU004 : labels.LBL0083}</Link>}
              {order.includes('MENU005') && <Link to="/about-us">{header?.MENU005 ? header?.MENU005 : labels.LBL0219}</Link>}
              {order.includes('MENU006') && <Link to={"/contact"} >{header?.MENU006 ? header?.MENU006 : labels.LBL0125}</Link>}
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0221}</h2>
            <div className='footer-links'>
              <Link to={`/${i18n.language}/faq`}>{labels.LBL0223}</Link>
              <Link to={`/${i18n.language}/terms-and-conditions`}>{labels.LBL0206}</Link>
              <Link to={`/${i18n.language}/privacy-policy`}>{labels.LBL0075}</Link>
              <Link to={`/${i18n.language}/cookie-policy`}>{labels.LBL0128}</Link>
            </div>
          </div>
          <div className='col-12 lg:col-3 md:col-6'>
            <h2>{labels.LBL0224}</h2>
            <div className='footer-links'>
              <Link to={`tel:${general.GEN0007}`}>{general.GEN0007}</Link>
              <Link to={`mailto:${general.GEN0008}`}>{general.GEN0008}</Link>
            </div>
          </div>
        </div>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
          <p>&copy;{year} {labels.LBL0065} | {labels.LBL0064} <Link target='_blank' className='underline' to="https://www.bluehouse-technology.co.uk/">Bluehouse Technology.</Link></p>
          <div className='right'>
            <span>{globalConfig.domainName + " " + labels.LBL0086}</span>
            <ul className='flex align-items-center'>
              <li><PR.Image src={visa} alt="Visa" /></li>
              <li><PR.Image src={masterCard} alt="Master Card" /></li>
              <li><PR.Image src={appleIcon} alt="Apple Pay" /></li>
              <li><PR.Image src={gpayIcon} alt="Google Pay" /></li>
            </ul>
          </div>
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer
