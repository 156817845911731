import React, { useEffect, useRef, useState } from "react";
import "../country-page/CountryPage.scss";
import HeaderMenu from "../../components/layout/HeaderMenu";
import BundlesList from "../../components/EsimBundles";
import { useSelector } from "react-redux";
import WorldMap from "react-svg-worldmap";
import { countryBlurbEn } from "../../assets/countryBlurb/countryBlurbEn";
import { countryBlurbNl } from "../../assets/countryBlurb/countryBlurbNl";
import { useToast } from "../../context/ToastContext";

const CountryPage = () => {
  const countryImage = useSelector(state => state.country.countryImage);
  const getCountry = useSelector((state) => state.country.country);
  const ref = useRef(null);
  const {labels} = useToast()
  const [blurb, setBlurb] = useState("");
  const [mapCountry, setMapCountry] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const language = localStorage.getItem('i18nextLng') !==  null ? localStorage.getItem('i18nextLng') : 'en'

  useEffect(() => {
    if (getCountry?.iso) {
      const countryBlurb = language === "en" ? countryBlurbEn : language === "nl" ? countryBlurbNl : []
      const getBlurbContent = countryBlurb?.find(c => c?.iso === getCountry.iso);
      if (getBlurbContent) {
        const splitHeading = getBlurbContent?.heading?.split(",");
        const heading1 = splitHeading[0];
        splitHeading[0] = "";
        const heading2 = splitHeading?.join(",");
        const blurbContent = mapCountry?.length === 0 && heading1 && heading2 ? { heading1, heading2, content: getBlurbContent?.content } : "";
        setBlurb(blurbContent)
      } else {
        setBlurb("")
      }
    }
  }, [getCountry.iso, language, mapCountry])

  useEffect(() => {
    if (mapCountry?.length > 0) {
      setCountryData(mapCountry)
    } else {
      setCountryData([{ country: getCountry?.iso ? getCountry?.iso : 'NL', value: "" }])
    }
  }, [getCountry?.iso, mapCountry])

  useEffect(() => {
    localStorage.removeItem("PAYMENT-RESPONSE");
  }, []);

  return (   
    <>
      {/* Header Section */}
      <section className="country-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
      </section>
      {/* Plans Section  */}
      <section className="plan-section heading" ref={ref}>
        <div className="grid align-items-center">
          <h2 className="country-bundle-h2">{labels.LBL0204}</h2>
        </div>
      </section>
      <section className="plan-section data-plans-section"
        style={{
          backgroundImage:
            countryImage 
            ?  `url(${countryImage})`
            : `url(${require('../../assets/eSimpleCountryImages/Countries/default-image.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>

        <div className="card">
          <div className="bundles-section" >
            <BundlesList setMapCountry={setMapCountry} />
          </div>
        </div>
      </section>
     {!blurb && mapCountry?.length === 0 
     ? null
     : <section className="country-info-section">
     <div className="grid grid-nogutter align-items-center">
       <div className="col-12 lg:col-7 md:col-7">
         <h1><span>{blurb?.heading1}</span> {blurb?.heading2} </h1>
         <p>{blurb?.content}</p>
       </div>
       <div className={`col-12 lg:col-${blurb ? "5" : "12"} md:col-${blurb ? "5" : "12"} text-center`}>
         <WorldMap
           color="#004dac"
           backgroundColor="#fbfbfb00"
           strokeOpacity="0.4"
           size="xl"
           data={countryData}
         />
       </div>
     </div>
   </section>
    }
    </>
  );
};

export default CountryPage;