import { createSlice } from '@reduxjs/toolkit';
const getCountry = localStorage.getItem('COUNTRY');
const getIPCOUNTRY = localStorage.getItem('IPCOUNTRY');
const getSelectedCountryImage = localStorage.getItem('selectedCountryImage');
const getSelectedRegionalImage = localStorage.getItem('selectedRegionalImage');
const getSelectedGlobalImage = localStorage.getItem('selectedGlobalImage');


const country = (getCountry === 'undefined' || getCountry === "") ? JSON.stringify(getCountry) : (getCountry !== null) ? JSON.parse(getCountry): {};
const IPCountry = (getIPCOUNTRY === 'undefined' || getIPCOUNTRY === "") ? JSON.stringify(getIPCOUNTRY) : (getIPCOUNTRY !== null) ? JSON.parse(getIPCOUNTRY): '';
const selectedCountryImage = getSelectedCountryImage || null;
const selectedRegionalImage = getSelectedRegionalImage || null;
const selectedGlobalImage = getSelectedGlobalImage || null;   

const initialCountry = {
    country: country,
    IPCountry:IPCountry,
    countryImage: selectedCountryImage,
    regionalImage: selectedRegionalImage,
    globalImage: selectedGlobalImage,
    homePageCountry: null 
}

export const countrySlice = createSlice({
    name: 'country',
    initialState: initialCountry,
    reducers: {
        setCountry(state, action) {
            state.country = action.payload
            localStorage.setItem("COUNTRY", JSON.stringify(action.payload));
        },
        setIpCountry(state,action){
            state.IPCountry = action.payload
            localStorage.setItem("IPCOUNTRY", JSON.stringify(action.payload));
        },
        setCountryImage(state, action) {
            state.countryImage = action.payload;
            localStorage.setItem("selectedCountryImage", action.payload);
        },
    },
})

export const countryActions = countrySlice.actions;
export default countrySlice.reducer;

window.addEventListener('beforeunload', () => {
    localStorage.removeItem('selectedRegionalImage');
    localStorage.removeItem('selectedGlobalImage');
});
