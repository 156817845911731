export const eSimpleImages = [
    {
      name: "Afghanistan",
      iso: "AF",
      image: require("./Countries/Afghanistan.jpg"),
    }, 
    { name: "Albania", iso: "AL", image: require("./Countries/Albania.jpg") },
    { name: "Algeria", iso: "DZ", image: require("./Countries/Algeria.jpg") },
    {
      name: "American Samoa",
      iso: "AS",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Andorra", iso: "AD", image: require("./Countries/Andorra.jpg") },
    { name: "Angola", iso: "AO", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Anguilla", iso: "AI", image: require("./Countries/Anguilla.jpg") },
    { name: "Antigua And Barbuda", iso: "AG", image: require("./Countries/Antigua-Barbuda.jpg") },
    { name: "Antilles", iso: "AN", image: require("./Countries/Antilles.jpg") },
    {
      name: "Antarctica",
      iso: "AQ",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Argentina", iso: "AR", image: require("./Countries/Argentina.jpg") },
    { name: "Armenia", iso: "AM", image: require("./Countries/Armenia.jpg") },
    { name: "Aruba", iso: "AW", image: require("./Countries/Aruba.jpg") }, 
    { name: "Australia", iso: "AU", image: require("./Countries/Australia.jpg") },
    { name: "Austria", iso: "AT", image: require("./Countries/Austria.jpg") },
    {
      name: "Azerbaijan",
      iso: "AZ",
      image: require("./Countries/Azerbaijan.jpg"),
    },
    { name: "Bahamas", iso: "BS", image: require("./Countries/Bahamas.jpg") },
    { name: "Bahrain", iso: "BH", image: require("./Countries/Bahrain.jpg") },
    {
      name: "Bangladesh",
      iso: "BD",
      image: require("./Countries/Bangladesh.jpg"),
    },
    { name: "Barbados", iso: "BB", image: require("./Countries/Barbados.jpg") },
    { name: "Belarus", iso: "BY", image: require("./Countries/Belarus.jpg") },
    { name: "Belgium", iso: "BE", image: require("./Countries/Belgium.jpg") },
    { name: "Belize", iso: "BZ", image: require("./Countries/Belize.jpg") }, 
    { name: "Benin", iso: "BJ", image: require("./Countries/Benin.jpg") },
    { name: "Bermuda", iso: "BM", image: require("./Countries/Bermuda.jpg") }, 
    { name: "Bhutan", iso: "BT", image: require("./Countries/Bhutan.jpg") }, 
    { name: "Bolivia", iso: "BO", image: require("./Countries/Bolivia.jpg") },
    { name: "Bonaire", iso: "BQ", image: require("./Countries/Bonaire.jpg") }, 
    { name: "Botswana", iso: "BW", image: require("./Countries/Botswana.jpg") },
    {
      name: "Bouvet Island",
      iso: "BV",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Brazil", iso: "BR", image: require("./Countries/Brazil.jpg") },
    {
      name: "British Indian Ocean Territory",
      iso: "IO",
      image: require("./Countries/British-virgin-islands.jpg"),
    },
    {
      name: "Brunei Darussalam",
      iso: "BN",
      image: require("./Countries/Brunei.jpg"),
    },
    { name: "Bulgaria", iso: "BG", image: require("./Countries/Bulgaria.jpg") },
    { name: "Burundi", iso: "BI", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "Cabo Verde",
      iso: "CV",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Cambodia", iso: "KH", image: require("./Countries/Cambodia.jpg") },
    { name: "Cameroon", iso: "CM", image: require("./Countries/Cameroon.jpg") },
    { name: "Canada", iso: "CA", image: require("./Countries/Canada.jpg") },
    { name: "Chad", iso: "TD", image: require("./Countries/Chad.jpg") },
    { name: "Chile", iso: "CL", image: require("./Countries/Chile.jpg") },
    { name: "China", iso: "CN", image: require("./Countries/China.jpg") },
    {
      name: "Christmas Island",
      iso: "CX",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Cocos Islands",
      iso: "CC",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Colombia", iso: "CO", image: require("./Countries/Colombia.jpg") },
    { name: "Comoros", iso: "KM", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Congo", iso: "CG", image: require("./Countries/Congo.jpg") }, 
    {
      name: "Cook Islands",
      iso: "CK",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Cote d'Ivoire",
      iso: "CI",
      image: require("./Countries/Cote-divoire.jpg"),
    }, 
    { name: "Croatia", iso: "HR", image: require("./Countries/Croatia.jpg") },
    { name: "Cuba", iso: "CU", image: require("./Countries/Cuba.jpg") }, 
    { name: "Curacao", iso: "CW", image: require("./Countries/Curacao.jpg") }, 
    { name: "Cyprus", iso: "CY", image: require("./Countries/Cyprus.jpg") },
    { name: "Denmark", iso: "DK", image: require("./Countries/Denmark.jpg") },
    { name: "Djibouti", iso: "DJ", image: require("./Countries/default-image.jpg") }, 
    { name: "Dominica", iso: "DM", image: require("./Countries/Dominica.jpg") },
    { name: "Dominican Republic", iso: "DO", image: require("./Countries/dominican-republic.jpg") },
    { name: "Ecuador", iso: "EC", image: require("./Countries/Ecuador.jpg") },
    { name: "Egypt", iso: "EG", image: require("./Countries/Egypt.jpg") },
    {
      name: "Equatorial Guinea",
      iso: "GQ",
      image: require("./Countries/default-image.jpg"), //missing
    },
    { name: "Eritrea", iso: "ER", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Estonia", iso: "EE", image: require("./Countries/Estonia.jpg") },
    { name: "Ethiopia", iso: "ET", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Europe+", iso:"Europe+", image: require("./Countries/Europe.jpg")},
    {
      name: "Faroe Islands",
      iso: "FO",
      image: require("./Countries/Faroe-islands.jpg"),
    }, 
    { name: "Fiji", iso: "FJ", image: require("./Countries/Fiji.jpg") },
    { name: "Finland", iso: "FI", image: require("./Countries/Finland.jpg") },
    { name: "France", iso: "FR", image: require("./Countries/France.jpg") },
    {
      name: "French Polynesia",
      iso: "PF",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "French Southern Territories",
      iso: "TF",
      image: require("./Countries/default-image.jpg"),
    }, //missing //eSIMgo
    { name: "Gabon", iso: "GA", image: require("./Countries/Gabon.jpg") },
    { name: "Gambia", iso: "GM", image: require("./Countries/Gambia.jpg") }, 
    { name: "Georgia", iso: "GE", image: require("./Countries/Georgia.jpg") },
    { name: "Germany", iso: "DE", image: require("./Countries/Germany.jpg") },
    { name: "Ghana", iso: "GH", image: require("./Countries/Ghana.jpg") },
    {
      name: "Gibraltar",
      iso: "GI",
      image: require("./Countries/Gibraltar.jpg"),
    },
    {
      name: "Global",
      iso: "Global",
      image: require("./Countries/Global.jpg"),
    },
    { name: "Greece", iso: "GR", image: require("./Countries/Greece.jpg") },
    {
      name: "Greenland",
      iso: "GL",
      image: require("./Countries/Greenland.jpg"),
    },
    { name: "Grenada", iso: "GD", image: require("./Countries/Grenada.jpg") },
    {
      name: "Guadeloupe",
      iso: "GP",
      image: require("./Countries/Guadeloupe.jpg"),
    },
    { name: "Guam", iso: "GU", image: require("./Countries/Guam.jpg") },
    {
      name: "Guatemala",
      iso: "GT",
      image: require("./Countries/Guatemala.jpg"),
    },
    { name: "Guinea", iso: "GN", image: require("./Countries/Guinea.jpg") },
    { name: "Guyana", iso: "GY", image: require("./Countries/Guyana.jpg") }, 
    { name: "Haiti", iso: "HT", image: require("./Countries/Haiti.jpg") }, 
    {
      name: "Heard Island and McDonald Islands",
      iso: "HM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Holy See", iso: "VA", image: require("./Countries/default-image.jpg") }, //missing //eSIMgo
    { name: "Honduras", iso: "HN", image: require("./Countries/Honduras.jpg") },
    { name: "Hong Kong", iso: "HK", image: require("./Countries/Hong-kong.jpg") },
    { name: "Hungary", iso: "HU", image: require("./Countries/Hungary.jpg") },
    { name: "Iceland", iso: "IS", image: require("./Countries/Iceland.jpg") },
    { name: "India", iso: "IN", image: require("./Countries/India.jpg") },
    {
      name: "Indonesia",
      iso: "ID",
      image: require("./Countries/Indonesia.jpg"),
    },
    { name: "Iran", iso: "IR", image: require("./Countries/Iran-islamic-republic-of.jpg") }, 
    { name: "Iraq", iso: "IQ", image: require("./Countries/Iraq.jpg") },
    { name: "Ireland", iso: "IE", image: require("./Countries/Ireland.jpg") },
    { name: "Israel", iso: "IL", image: require("./Countries/Israel.jpg") },
    { name: "Italy", iso: "IT", image: require("./Countries/Italy.jpg") },
    { name: "Jamaica", iso: "JM", image: require("./Countries/Jamaica.jpg") },
    { name: "Japan", iso: "JP", image: require("./Countries/Japan.jpg") },
    { name: "Jersey", iso: "JE", image: require("./Countries/Jersey.jpg") },
    { name: "Jordan", iso: "JO", image: require("./Countries/Jordan.jpg") },
    {
      name: "Kazakhstan",
      iso: "KZ",
      image: require("./Countries/Kazakhstan.jpg"),
    },
    { name: "Kenya", iso: "KE", image: require("./Countries/Kenya.jpg") },
    { name: "Kiribati", iso: "KI", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Korea", iso: "KP", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Kosovo", iso: "XK", image: require("./Countries/Kosovo.jpg") },
    { name: "Kuwait", iso: "KW", image: require("./Countries/Kuwait.jpg") },
    {
      name: "Kyrgyzstan",
      iso: "KG",
      image: require("./Countries/Kyrgyzstan.jpg"),
    },
    {
      name: "Lao People's Democratic Republic",
      iso: "LA",
      image: require("./Countries/Laos.jpg"),
    },
    { name: "Latvia", iso: "LV", image: require("./Countries/Latvia.jpg") },
    { name: "Lebanon", iso: "LB", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Lesotho", iso: "LS", image: require("./Countries/Lesotho.jpg") },
    { name: "Liberia", iso: "LR", image: require("./Countries/Liberia.jpg") },
    { name: "Libya", iso: "LY", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "Liechtenstein",
      iso: "LI",
      image: require("./Countries/Liechtenstein.jpg"),
    },
    {
      name: "Lithuania",
      iso: "LT",
      image: require("./Countries/Lithuania.jpg"),
    },
    {
      name: "Luxembourg",
      iso: "LU",
      image: require("./Countries/Luxembourg.jpg"),
    },
    { name: "Macau", iso: "MO", image: require("./Countries/Macau.jpg") },
    {
      name: "Madagascar",
      iso: "MG",
      image: require("./Countries/Madagascar.jpg"),
    },
    { name: "Malawi", iso: "MW", image: require("./Countries/Malawi.jpg") },
    { name: "Malaysia", iso: "MY", image: require("./Countries/Malaysia.jpg") },
    { name: "Maldives", iso: "MV", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Mali", iso: "ML", image: require("./Countries/Mali.jpg") },
    { name: "Malta", iso: "MT", image: require("./Countries/Malta.jpg") },
    {
      name: "Marshall Islands",
      iso: "MH",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Martinique",
      iso: "MQ",
      image: require("./Countries/Martinique.jpg"),
    },
    {
      name: "Mauritius",
      iso: "MU",
      image: require("./Countries/Mauritius.jpg"),
    },
    { name: "Mexico", iso: "MX", image: require("./Countries/Mexico.jpg") },
    {
      name: "Micronesia",
      iso: "FM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Moldova", iso: "MD", image: require("./Countries/Moldova.jpg") },
    { name: "Monaco", iso: "MC", image: require("./Countries/Monaco.jpg") },
    { name: "Mongolia", iso: "MN", image: require("./Countries/Mongolia.jpg") },
    {
      name: "Montenegro",
      iso: "ME",
      image: require("./Countries/Montenegro.jpg"),
    },
    {
      name: "Montserrat",
      iso: "MS",
      image: require("./Countries/Montserrat.jpg"),
    },
    { name: "Morocco", iso: "MA", image: require("./Countries/Morocco.jpg") },
    {
      name: "Mozambique",
      iso: "MZ",
      image: require("./Countries/Mozambique.jpg"),
    },
    { name: "Myanmar", iso: "MM", image: require("./Countries/default-image.jpg") }, //missing //eSIMgo
    { name: "Namibia", iso: "NA", image: require("./Countries/Namibia.jpg") },
    { name: "Nauru", iso: "NR", image: require("./Countries/Nauru.jpg") },
    { name: "Nepal", iso: "NP", image: require("./Countries/default-image.jpg") },
    {
      name: "Netherlands",
      iso: "NL",
      image: require("./Countries/Netherlands.jpg"),
    },
    {
      name: "New Caledonia",
      iso: "NC",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "New Zealand",
      iso: "NZ",
      image: require("./Countries/New-zealand.jpg"),
    },
    {
      name: "Nicaragua",
      iso: "NI",
      image: require("./Countries/Nicaragua.jpg"),
    },
    { name: "Niger", iso: "NE", image: require("./Countries/Niger.jpg") },
    { name: "Nigeria", iso: "NG", image: require("./Countries/Nigeria.jpg") },
    { name: "Niue", iso: "NU", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "Norfolk Island",
      iso: "NF",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "North Macedonia",
      iso: "MK",
      image: require("./Countries/default-image.jpg"),
    }, //missing //eSIMgo
    {
      name: "Northern Mariana Islands",
      iso: "MP",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "North America",
      iso: "US",
      image: require("./Countries/North-america.jpg"),
    },
    { name: "Norway", iso: "NO", image: require("./Countries/Norway.jpg") },
    { name: "Oman", iso: "OM", image: require("./Countries/Oman.jpg") },
    { name: "Pakistan", iso: "PK", image: require("./Countries/Pakistan.jpg") },
    { name: "Palau", iso: "PW", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "Palestine, State of",
      iso: "PS",
      image: require("./Countries/palestine-state-of.jpg"),
    },
    { name: "Panama", iso: "PA", image: require("./Countries/Panama.jpg") },
    {
      name: "Papua New Guinea",
      iso: "PG",
      image: require("./Countries/Papua-new-guinea.jpg"),
    },
    { name: "Paraguay", iso: "PY", image: require("./Countries/Paraguay.jpg") },
    { name: "Peru", iso: "PE", image: require("./Countries/Peru.jpg") },
    {
      name: "Philippines",
      iso: "PH",
      image: require("./Countries/Philippines.jpg"),
    },
    // { name: "Pitcairn", iso: "PN", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Poland", iso: "PL", image: require("./Countries/Poland.jpg") },
    { name: "Portugal", iso: "PT", image: require("./Countries/Portugal.jpg") },
    {
      name: "Puerto Rico",
      iso: "PR",
      image: require("./Countries/Puerto-rico-us.jpg"),
    },
    { name: "Qatar", iso: "QA", image: require("./Countries/Qatar.jpg") },
    { name: "Reunion", iso: "RE", image: require("./Countries/reunion.jpg") },
    { name: "Romania", iso: "RO", image: require("./Countries/Romania.jpg") },
    { name: "Rwanda", iso: "RW", image: require("./Countries/Rwanda.jpg") },
    {
      name: "Saint Barth\u00e9lemy",
      iso: "BL",
      image: require("./Countries/Saint-barthelemy.jpg"),
    },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      iso: "SH",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Saint Kitts and Nevis",
      iso: "KN",
      image: require("./Countries/Saint-kitts-and-nevis.jpg"),
    },
    {
      name: "Saint Lucia",
      iso: "LC",
      image: require("./Countries/Saint-lucia.jpg"),
    },
    {
      name: "Saint Martin",
      iso: "MF",
      image: require("./Countries/Saint-martinfrench-part.jpg"),
    },
    {
      name: "Saint Pierre and Miquelon",
      iso: "PM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Saint Vincent and the Grenadines",
      iso: "VC",
      image: require("./Countries/saint-vincent-and-the-grenadines.jpg"),
    },
    { name: "Samoa", iso: "WS", image: require("./Countries/Samoa.jpg") }, 
    {
      name: "San Marino",
      iso: "SM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Sao Tome and Principe",
      iso: "ST",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Saudi Arabia",
      iso: "SA",
      image: require("./Countries/Saudi-arabia.jpg"),
    },
    { name: "Senegal", iso: "SN", image: require("./Countries/Senegal.jpg") },
    { name: "Serbia", iso: "RS", image: require("./Countries/Serbia.jpg") },
    {
      name: "Seychelles",
      iso: "SC",
      image: require("./Countries/Seychelles.jpg"),
    },
    {
      name: "Sierra Leone",
      iso: "SL",
      image: require("./Countries/Sierra-leone.jpg"),
    }, 
    { name: "Singapore", iso: "SG", image: require("./Countries/Singapore.jpg") },
    {
      name: "Sint Maarten",
      iso: "SX",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Slovakia", iso: "SK", image: require("./Countries/Slovakia.jpg") },
    { name: "Slovenia", iso: "SI", image: require("./Countries/Slovenia.jpg") },
    {
      name: "Solomon Islands",
      iso: "SB",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Somalia", iso: "SO", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "South Africa",
      iso: "ZA",
      image: require("./Countries/South-africa.jpg"),
    },
    {
      name: "South Korea",
      iso: "KR",
      image: require("./Countries/South-korea.jpg"),
    },
    {
      name: "South Sudan",
      iso: "SS",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Spain", iso: "ES", image: require("./Countries/Spain.jpg") },
    {
      name: "Sri Lanka",
      iso: "LK",
      image: require("./Countries/Sri-lanka.jpg"),
    },
    { name: "Suriname", iso: "SR", image: require("./Countries/Suriname.jpg") },
    {
      name: "Svalbard and Jan Mayen",
      iso: "SJ",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Sweden", iso: "SE", image: require("./Countries/Sweden.jpg") },
    {
      name: "Switzerland",
      iso: "CH",
      image: require("./Countries/Switzerland.jpg"),
    },
    {
      name: "Syrian Arab Republic",
      iso: "SY",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Taiwan",
      iso: "TW",
      image: require("./Countries/Taiwan.jpg"),
    },
    {
      name: "Tajikistan",
      iso: "TJ",
      image: require("./Countries/Tajikistan.jpg"),
    },
    {
      name: "Tanzania",
      iso: "TZ",
      image: require("./Countries/Tanzania.jpg"),
    },
    { name: "Thailand", iso: "TH", image: require("./Countries/Thailand.jpg") },
    {
      name: "Timor-Leste",
      iso: "TL",
      image: require("./Countries/Timor-leste.jpg"),
    }, 
    { name: "Togo", iso: "TG", image: require("./Countries/Togo.jpg") },
    { name: "Tokelau", iso: "TK", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Tonga", iso: "TO", image: require("./Countries/Tonga.jpg") },
    {
      name: "Trinidad and Tobago",
      iso: "TT",
      image: require("./Countries/Trinidad-and-tobago.jpg"),
    },
    { name: "Tunisia", iso: "TN", image: require("./Countries/Tunisia.jpg") },
    { name: "Turkey", iso: "TR", image: require("./Countries/Turkey.jpg") },
    {
      name: "Turkmenistan",
      iso: "TM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Turks and Caicos Islands",
      iso: "TC",
      image: require("./Countries/Turks-and-caicos-islands.jpg"),
    },
    { name: "Tuvalu", iso: "TV", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Uganda", iso: "UG", image: require("./Countries/Uganda.jpg") },
    { name: "Ukraine", iso: "UA", image: require("./Countries/Ukraine.jpg") },
    {
      name: "United Kingdom",
      iso: "GB",
      image: require("./Countries/United-kingdom.jpg"),
    },
    {
      name: "United States",
      iso: "US",
      image: require("./Countries/United-states.jpg"),
    },
    {
      name: "United Arab Emirates",
      iso: "AE",
      image: require("./Countries/United-states.jpg"),
    }, //missing
    {
      name: "United States Minor Outlying Islands",
      iso: "UM",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Uruguay", iso: "UY", image: require("./Countries/Uruguay.jpg") },
    {
      name: "Uzbekistan",
      iso: "UZ",
      image: require("./Countries/Uzbekistan.jpg"),
    },
    {
      name: "Venezuela",
      iso: "VE",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Vietnam", iso: "VN", image: require("./Countries/Vietnam.jpg") },
    {
      name: "Virgin Islands British",
      iso: "VG",
      image: require("./Countries/British-virgin-islands.jpg"),
    },
    {
      name: "Default Image",
      iso: "DEF",
      image: require("./Countries/default-image.jpg"),
    },
    {
      name: "Wallis and Futuna",
      iso: "WF",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    {
      name: "Western Sahara",
      iso: "EH",
      image: require("./Countries/default-image.jpg"),
    }, //missing
    { name: "Yemen", iso: "YE", image: require("./Countries/default-image.jpg") }, //missing
    { name: "Zambia", iso: "ZM", image: require("./Countries/Zambia.jpg") },
    { name: "Zimbabwe", iso: "ZW", image: require("./Countries/default-image.jpg") }, //missing
    {
      name: "Canary Islands",
      iso: "IC",
      image: require("./Countries/Canary-islands.jpg"),
    },
    {
      name: "Northern Cyprus",
      iso: "CYP",
      image: require("./Countries/Northern-cyprus.jpg"),
    },
    {
      name: "Latin America",
      iso: "LATAM",
      image: require("./Countries/Latin-america.jpg"),
    },
    {
      name: "Africa",
      iso: "Africa",
      image: require("./Regional/Africa.jpg")
    },
    {
      name: "Asia",
      iso: "Asia",
      image: require("./Regional/Asia.jpg")
    },
    {
      name: "Europe+",
      iso: "Europe+",
      image: require("./Regional/Europe+.jpg")
    },
    {
      name: "Latin America",
      iso: "LATAM",
      image: require("./Regional/LatinAmerica.jpg")
    },
    {
      name: "Middle East",
      iso: "Middle East",
      image: require("./Regional/MiddleEast.jpg")
    },
    {
      name: "North America",
      iso: "North America",
      image: require("./Regional/NorthAmerica.jpg")
    },
    {
      name: "Oceania",
      iso: "Oceania",
      image: require("./Regional/Oceania.jpg")
    },
    {
      name: "Global",
      iso: "Global",
      image: require("./Global/Global.jpg")
    }
  ];
  