import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useNavigate } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { useTranslation } from "react-i18next";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import faq_nl from "../../assets/data/faq_nl.json"
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { useToast } from "../../context/ToastContext";
import { countryActions } from "../../store/country";
import { getCountriesList } from "../../services/api";
import { plausible } from "../../utils/plausibleTracker";
import getAssets from '../../assets/data/brandingAssets.json';

const Home = () => {
  const { i18n } = useTranslation();
  const {labels, home, general, showToast, isBase64Image} = useToast()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const [countries,setCountries] = useState([])
  const [faq, setFaq] = useState([]);
  const [homePageCountry, setHomePageCountry] = useState(null);
  const [countryData, setCountryData] = useState("")

  useEffect(() => {
    const defaultLang = i18n.language;
    if (defaultLang === "en") {
      setFaq(faq_en);
    } else if (defaultLang === "es") {
      setFaq(faq_es);
    } else if (defaultLang === "pt") {
      setFaq(faq_pt);
     } else if (defaultLang === "nl") {
      setFaq(faq_nl)
      }
  }, [i18n.language, dispatch]);

  const renderRouteName = (text) => {
    return text
      .normalize('NFD') 
      .replace(/[\u0300-\u036f]/g, '') 
      .replace(/[^a-zA-Z0-9\s-]/g, '') 
      .toLowerCase() 
      .trim() 
      .replace(/\s+/g, '-') 
      .replace(/-+/g, '-'); 
  };
  
   const onHomeCountryChange = (e) => {
    setHomePageCountry(e.value);
  } 

  const onHomeCountryHide = (e) => {
    if (homePageCountry) {
      const selectedCountry = countries.find(country => country.iso === homePageCountry);
      navigate(`/${i18n.language}/${renderRouteName(selectedCountry.name)}-esim`);
      trackCountry(selectedCountry)
      dispatch(countryActions.setCountry(selectedCountry));
      dispatch(scrollToBundlesActions.setScrollToBundles(true))
    }
  };

  const trackCountry = useCallback((countryName) => {
    plausible.trackEvent("country", { props: { country: countryName } });
  }, []);

  const getCountryData = useCallback(() => {
    if (countryData === "") {
      setCountryData(null);
      const getCountryList = (response) => {
        if (response.result === "SUCCESS") {
          const countriesData = response.data ? response.data : [];
          if (countriesData.length > 0) {
            const uniqueCountries = countriesData.filter(c => c.geog_type === "Local")
            setCountries(uniqueCountries);
          } else {
            showToast("info", "ESMBND-03");
          }
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg);
        }
      };
      const url = "countries";
      getCountriesList(url, headers, dispatch, getCountryList);
    }
  }, [countryData, headers, dispatch, showToast]);

  useEffect(() => {
    const pathname =  window.location.pathname?.toLowerCase();
    if (pathname === "/" || pathname?.includes('nl') || pathname?.includes('en')) {
      getCountryData();
    }
  }, [getCountryData])


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{general.GEN0009}</title>
        <meta property="og:description" content={general.GEN0010} />
      </Helmet>

      {/* Header Section */}
      <section className="header-section" style={{ background: `url(${isBase64Image(home.HOME0001) ?`data:image/png;base64,${home.HOME0001}` : getAssets.home.header_banner}) no-repeat` }}>
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-12 md:col-12">
            <div className="header-text-box">
              <h1>{home.HOME0002}</h1>
              <p>{home.HOME0003}</p>
              <div className='country-dropdown'>
                <PR.Dropdown
                  filter
                  value={homePageCountry}
                  options={countries}
                  onChange={(e) => onHomeCountryChange(e)}
                  onHide={(e) => onHomeCountryHide(e)}
                  resetFilterOnHide
                  optionLabel="name"
                  optionValue="iso"
                  placeholder={homePageCountry ? homePageCountry.name : home.HOME0004}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0007) ? `data:image/png;base64,${home.HOME0007}` : getAssets.home.instant_activation} alt="Instant Activation" />
              <h2>{home.HOME0005}</h2>
              <p>{home.HOME0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0010) ? `data:image/png;base64,${home.HOME0010}` : getAssets.home.attractive_rates} alt="Attractive Rates" />
              <h2>{home.HOME0008}</h2>
              <p>{home.HOME0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0013) ? `data:image/png;base64,${home.HOME0013}` : getAssets.home.global_coverage} alt="Global Coverage" />
              <h2>{home.HOME0011}</h2>
              <p>{home.HOME0012}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section pb-5">
        <div className="grid">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <h2>{labels.LBL0139}</h2>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0020) ? `data:image/png;base64,${home.HOME0020}` :getAssets.home.multi_network_connectivity}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                    <h3>{home.HOME0018}</h3>
                    <p>{home.HOME0019}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0023) ? `data:image/png;base64,${home.HOME0023}` : getAssets.home.fast_connections}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0021}</h3>
                  <p>{home.HOME0022}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0026) ? `data:image/png;base64,${home.HOME0026}` : getAssets.home.support_24_7}
                    alt="Support 24/7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0024}</h3>
                  <p>{home.HOME0025}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0029) ? `data:image/png;base64,${home.HOME0029}` : getAssets.home.easy_installation}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0027}</h3>
                  <p>{home.HOME0028}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0032) ? `data:image/png;base64,${home.HOME0023}` : getAssets.home.best_pricing_plans}
                    alt="Best Pricing Plans"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0030}</h3>
                  <p>{home.HOME0031}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(home.HOME0033) ? `data:image/png;base64,${home.HOME0033}` : getAssets.home.right_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-10 md:col-10 md:col-offset-1 lg:col-offset-1">
            <h2>{labels.LBL0136}</h2>
            {faq &&
              faq.length > 0 &&
              faq.slice(0, 1).map((item, i) => (
                <PR.Accordion key={i}>
                  {item.QA.slice(0, 4).map((qa, j) => (
                    <PR.AccordionTab header={qa.question} key={j}>
                      {qa.answer.map((an, h) => (
                        <div key={h}>
                          {an.content_heading && (
                            <p><strong>{an.content_heading}</strong></p>
                          )}
                          {an.content && (
                            <p>{an.content}</p>
                          )}
                          {an.olist?.length > 0 && (
                            <ol>
                              {an.olist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ol>
                          )}
                          {an.ulist?.length > 0 && (
                            <ul>
                              {an.ulist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </PR.AccordionTab>
                  ))}
                </PR.Accordion>
              ))}
          </div>
        </div>
        <div className="text-center faq-custom-margin">
            <Link to="/faq" className="view-all-button">{labels.LBL0213}</Link>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Home;