import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as PR from "../prime-modules/index";
import { useDispatch, useSelector } from "react-redux";
import { responsiveOptions } from "../constants";
import { getCatalogsList, getCountriesList } from "../services/api";
import { countryActions } from "../store/country";
import { cartFilterActions } from "../store/cart";
import { affiliateTrackingInfoActions } from "../store/affiliateTracking";
import { useLocation, useNavigate } from "react-router-dom";
import { geteSIMsDescription, renderPrice, setDataAmount, setNumberPrecision } from "../utils/reuse";
import { plausible } from "../utils/plausibleTracker";
import { useTranslation } from "react-i18next";
import MakePayment from "./payment/MakePayment";
import { BundleListLoading } from "./skeletonLoading/BundleListLoading";
import { esimIccidActions } from "../store/esim";
import { infoCircle, esimIcon, formsLogo, speedIcon } from "../assets/images/index";
import { eSimpleImages } from "../assets/eSimpleCountryImages/eSimpleImages";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import RoamingCountries from "../shared/components/RoamingCountries";
import Roaming from "../assets/images/Roaming.png"
import { useToast } from "../context/ToastContext";
import { unlimitedPlanUsageAsterisk } from "./helper";

const BundlesList = ({ setMapCountry }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, labels } = useToast();
  const location = useLocation();
  const getCartData = useSelector((state) => state.cart.catalogCart);
  const getCountry = useSelector((state) => state.country.country);
  const getIPCountry = useSelector((state) => state.country.IPCountry);
  const iccid = useSelector((state) => state.esim.esimIccid);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState("");
  const [catalogData, setCatalogData] = useState({});
  const [bundleInfoVisible, setBundleInfoVisible] = useState(false);
  const [displayNetworks, setDisplayNetworks] = useState(false);
  const direction = 1;
  const orderBy = "price";
  const filter = "";
  const filterBy = "";
  const page = "";
  const limit = "";
  const queryParams = new URLSearchParams(useLocation().search);
  const afid = queryParams.get("afid");
  const [checkoutFormState, setCheckoutFormState] = useState(false);

  const [catalogs, setCatalogs] = useState([]);
  const [countryVal, setCountryVal] = useState(true);
  const [selectedDropdown, setSelectedDropdown] = useState('')
  const [country,setCountry] = useState('');
  const [countries,setCountries] = useState([]);
  const [localCountry, setLocalCountry] = useState("");
  const [regionalCountry, setRegionalCountry] = useState("");
  const [globalCountry, setGlobalCountry] = useState("");

  const getCatalogs = useCallback(() => {
    if (countryVal) {
      const getCatalogList = (response) => {
        if (response.result === "SUCCESS") {
          const catalogList =
            response.data && response.data.bundles ? response.data.bundles : [];
          if (catalogList.length > 0) {
            let sortedCatalogs = catalogList.sort((a, b) => a.price - b.price);
            setCatalogs(sortedCatalogs);

            //Set countries map if it is a regional or global
            if (selectedDropdown !== "Local" && sortedCatalogs[0]?.roamingEnabled?.length > 0) {
              const mapCountries = sortedCatalogs[0]?.roamingEnabled?.map(c => ({ country: c?.country?.iso, value: "" }))
                setMapCountry(mapCountries)
            } else {
              setMapCountry([])
            }
          } else {
            showToast('info', 'ESMBND-02')
          }
          const selectedCountry = countries.find(
            (country) => country.iso === getCountry?.iso
          );
          selectedCountry?.geog_type === "Local" && dispatch(countryActions.setCountry(selectedCountry));
        } else {
          const error = response.error;
          if(error.errorMsg !== "GNRMSG-03"){
            showToast(error.severity, error.errorMsg)
          }
        }
        setLoading(false);
      };
      if (
        (country)
      ) {
        setLoading(true);
        setCatalogs([]);

        let directionParam;
        if (direction === 1) {
          directionParam = "asc";
        } else {
          directionParam = "desc";
        }
        const encodedParam = encodeURIComponent(country?.iso);

        const getIccidParam = isAuth && iccid ? "&iccid=" + iccid : "";

        const queryParameters =
          "countries=" +
          encodedParam +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&direction=" +
          directionParam +
          "&orderBy=" +
          orderBy +
          "&filter=" +
          filter +
          "&filterBy=" +
          filterBy +
          getIccidParam;

        getCatalogsList(queryParameters, headers, dispatch, getCatalogList);
      }
    }
  }, [countryVal, country, countries, dispatch, selectedDropdown, setMapCountry, showToast, getCountry?.iso, isAuth, iccid, headers]);

  const setDataDuration = (duration) => {
    return duration && duration + labels.LBL0207;
  };

  const setDataSpeed = (speed) => {
    return speed && speed.join("/");
  };

  const getNetworks = (countries) => {
    let networks = [];
    if (countries && countries.length > 0) {
      const networksList = countries[0]["networks"];
      if (networksList && networksList.length > 0) {
        networks = networksList;
      }
    }
    return networks;
  };

  const setNetworks = (countries) => {
    const networks = getNetworks(countries);

    return (
      networks &&
      (networks.length === 0 ? (
        <span>N/A</span>
      ) : (
        <span className="networkData">
          {networks.map((n, index) => (
            <span key={index} className="catalogNetworks">
              {n.name} {n.speeds && "(" + setDataSpeed(n.speeds) + ")"}
            </span>
          ))}
        </span>
      ))
    );
  };

  const clearStoreContent = useCallback(() => {
    dispatch(cartFilterActions.deleteCatalogCart());
    dispatch(cartFilterActions.setCatalogCartAmount(0));
  }, [dispatch]);

  const trackCountry = useCallback((countryName) => {
    plausible.trackEvent("country", { props: { country: countryName } });
  }, []);

  const getImageUrl = (iso) => {
    const countryImage = eSimpleImages.find((country) => country.iso === iso);
    return countryImage ? countryImage.image : null;
  };

  const renderRouteName = (text) => {
    return text?.normalize('NFD') 
      .replace(/[\u0300-\u036f]/g, '') 
      .replace(/[^a-zA-Z0-9\s-]/g, '') 
      .toLowerCase() 
      .trim() 
      .replace(/\s+/g, '-') 
      .replace(/-+/g, '-'); 
  };
  
  const getCountryNameByIso = useCallback((iso) => countries?.find(c => c?.iso === iso)?.name, [countries]);

  const onCountryChange = (e) => {
    if (e.value) {
      setCountry(e.value);
      e.value.geog_type === "Local" && setLocalCountry(e.value);
      e.value.geog_type === "Regional" && setRegionalCountry(e.value);
      //There is no onCountryChange method in Global country dropdown, because there is onlt one global bundle.
      setCountryVal(false);
    }
  };

  const onCountryHide = useCallback( (e) => {
    setCountryVal(true);
    if(e){
    setSelectedDropdown(e?.geog_type)
    const imageUrl = getImageUrl(e?.iso);
    dispatch(countryActions.setCountryImage(imageUrl));
    const getFilteredCountry = countries.find((x) => x.iso === e?.iso);
    getFilteredCountry?.geog_type === "Local" && dispatch(countryActions.setCountry(getFilteredCountry));
    navigate(`/${i18n.language}/${renderRouteName(getFilteredCountry?.name) + "-esim"}`);
    }
  }, [countries, dispatch, navigate, i18n.language]);

  const onDropdownChange = useCallback((type) => {
    if(type !== selectedDropdown) {
    let iso;
    setSelectedDropdown(type);
    if(type === "Local") {
      iso = localCountry?.iso
    } else if (type === "Regional") {
      iso = regionalCountry?.iso
    } else if (type === "Global") {
      iso = globalCountry?.iso
    }
    const filteredCountry = countries?.find(c => c?.iso === iso);
    onCountryChange({value: filteredCountry})
    onCountryHide(filteredCountry)
    navigate(`/${i18n.language}/${renderRouteName(getCountryNameByIso(iso))}-esim`)
    const imageUrl = getImageUrl(iso);
    dispatch(countryActions.setCountryImage(imageUrl))
    }
  }, [countries, dispatch, getCountryNameByIso, globalCountry?.iso, localCountry?.iso, navigate, onCountryHide, regionalCountry?.iso, selectedDropdown, i18n.language])

  const setInitialDropdownCountry = (getFilteredCountry) => {
    const geog_type = getFilteredCountry?.geog_type
    if(geog_type === "Local") {
      setLocalCountry(getFilteredCountry);
    } else if(geog_type === "Regional") {
      setRegionalCountry(getFilteredCountry)
    } else if(geog_type === "Global") {
      setGlobalCountry(getFilteredCountry)
    }
  }

  useEffect(() => {
    //This useEffect works when iDEAL payment failed
    const errorCode = new URLSearchParams(location?.search).get("code");
    if(location?.search?.includes("error")) {
      showToast("info", errorCode);
    }
  }, [location.search, showToast])

  const setEmptyCountryDropDown = useCallback((list) => {
    if(localCountry === "") {
      const initialLocalCountry = list?.find(c => c?.iso === (getCountry?.iso ? getCountry.iso : "NL"));
      setLocalCountry(initialLocalCountry)
    }
    if(regionalCountry === "") {
      const initialRegionalCountry = list?.find(r => r?.geog_type === "Regional");
      setRegionalCountry(initialRegionalCountry)
    }
    if(globalCountry === "") {
      const initialGlobalCountry = list?.find(r => r?.geog_type === "Global");
      setGlobalCountry(initialGlobalCountry)
    }
  }, [getCountry.iso, globalCountry, localCountry, regionalCountry])
  
  const getCountryData = useCallback(() => {
      if(location?.pathname !== `/${i18n.language}/country-page` && location?.pathname !== `/country-page` && location?.pathname !== `/${i18n.language}/country-page` && !location?.pathname?.includes("-esim")) {
        navigate("page-not-found")
      } else {
        if (countryData === "" || location?.pathname.includes("/country-page")) {
          setCountryData(null);
          const getCountryList = (response) => {
            if (response.result === "SUCCESS") {
              const countriesData = response.data ? response.data : [];
              if (countriesData.length > 0) {
                setCountries(countriesData);
                  let defaultCountry = "";
                  if (getIPCountry !== response.ipcountry) {
                    clearStoreContent();
                    dispatch(countryActions.setIpCountry(response.ipcountry));
                  } else if (getCountry && getCountry.iso) {
                    const findCountry = countriesData.find((c) => c.iso === getCountry.iso)
                    defaultCountry = findCountry?.geog_type === "Local" ? findCountry.iso : "NL";
                  }
  
                if (location.pathname?.includes("-esim")) {
                  const routeSplit = location.pathname?.split('/')
                  const routeSplit3Length = routeSplit.length === 3 ? routeSplit[2].replace('-esim', '') : '';
                  const routeSplit2Length = routeSplit.length === 2 ? routeSplit[1].replace('-esim', '') : '';
                  const getCountryNameFromPath = routeSplit3Length ? routeSplit3Length : routeSplit2Length;
                  const isExistInCountriesList = countriesData?.find(r => renderRouteName(r.name) === getCountryNameFromPath || renderRouteName(r.iso) === getCountryNameFromPath);
                  if (isExistInCountriesList?.iso) {
                    defaultCountry = isExistInCountriesList.iso;
                  } else {
                    defaultCountry = response?.ipcountry === "IN" ? "NL" : response.ipcountry;
                  }
                }
                const getFilteredCountry = countriesData?.find(
                  (val) => val.iso === defaultCountry
                ); 
                
                setInitialDropdownCountry(getFilteredCountry);
                setEmptyCountryDropDown(countriesData);
                setCountry(getFilteredCountry);
                setSelectedDropdown(getFilteredCountry?.geog_type)
                getFilteredCountry?.geog_type === "Local" && setLocalCountry(getFilteredCountry)
                getFilteredCountry?.geog_type === "Regional" && setRegionalCountry(getFilteredCountry)
                navigate(`/${i18n.language}/${renderRouteName(getFilteredCountry?.name) + "-esim"}`)
                trackCountry(getFilteredCountry?.name);
                //renderImage
                const imageUrl = getImageUrl(getFilteredCountry?.iso)
                dispatch(countryActions.setCountryImage(imageUrl));
                //set the country in local storage if the country belongs to Local... If we store global and regional countries, Local country dropdown behaviour doesn't looks good...
                getFilteredCountry?.geog_type === "Local" && dispatch(countryActions.setCountry(getFilteredCountry));
                setCountryData(defaultCountry);
              } else {
                showToast("info", "ESMBND-03")
              }
            } else {
              const error = response.error;
              showToast(error.severity, error.errorMsg);
            }
          };
          const url = "countries";
          getCountriesList(url, headers, dispatch, getCountryList);
        }
      }
  }, [countryData, headers, dispatch, getIPCountry, getCountry, location.pathname, setEmptyCountryDropDown, navigate, trackCountry, clearStoreContent, showToast, i18n.language]);

  useEffect(() => {
    if (afid) {
      navigate("/");
      dispatch(affiliateTrackingInfoActions.setAffiliateTrackingInfo(afid));
    }
  }, [afid, dispatch, navigate]);

  useEffect(() => {
      dispatch(esimIccidActions.deleteEsim());
      getCountryData();
  }, [dispatch, getCountryData])

  useEffect(() => {
    if ((country ||selectedDropdown === "Regional" || (selectedDropdown === "Global"))) {
      getCatalogs();
    }
  }, [country, selectedDropdown, getCatalogs]);

  const catalogPurchase = (catalogData) => {
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);
    const getCartObj = { ...getCartData };

    if (getCartObj !== catalogName) {
      const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay,
      };
      dispatch(cartFilterActions.setCatalogCart(setCartData));
      dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
    }
    dispatch(esimIccidActions.deleteEsim());
    setCheckoutFormState(true);
  };

  const displayCatalogDetails = (catalogData) => {
    if(!!catalogData?.roamingEnabled){
      catalogData.roamingEnabled = catalogData?.roamingEnabled?.sort((a, b) => a.country.name.localeCompare(b.country.name))
    }
    setCatalogData(catalogData);
    setBundleInfoVisible(true);
  };

  const productTemplate = (rowData) => {
    const countryName = rowData?.countries?.length ? rowData?.countries[0]?.country?.name?.split("(")[0] : ''
    const description = geteSIMsDescription(rowData);
    rowData.description = description
    return (
      <>
        <div className="border-1 surface-border plan-box">
          <div className="flex align-items-center justify-content-between mb-3">
            <h3>{rowData.description} {unlimitedPlanUsageAsterisk(rowData?.dataAmountForDisplay, labels)}</h3>
            <PR.Image
              className="info-icon"
              src={infoCircle}
              alt={labels.LBL0016}
              onClick={() => {
                displayCatalogDetails(rowData);
              }}
            />
          </div>
          <div className="flex align-items-center mb-2">
            <h2>{renderPrice(rowData.price)}</h2>
          </div>
          <ul className="flex list-none gap-3">
            <li>
              <div>
                <tr>
                 <div className="flex align-items-center gap-3 mt-3">
                  <PR.Image src={Roaming} className="icons"/>
                  <td className="text-right">
                    <span>
                     <RoamingCountries roaming={rowData.roamingEnabled} countryName={countryName}/>
                    </span>
                  </td>
                </div>
                </tr>
                {rowData.speed && (
                  <div className="flex align-items-center gap-3 mt-3">
                    <PR.Image src={speedIcon} className="icons ml-1" alt="speed icon" />
                    <span>{rowData.speed.join(" - ")}</span>
                  </div>
                )}
              </div>
            </li>
          </ul>
          <PR.Button
            label={labels.LBL0021}
            className="goto-plans-button p-button-rounded"
            onClick={() => catalogPurchase(rowData)}
          />
        </div>
      </>
    );
  };

  const onHide = () => {
    setBundleInfoVisible(false);
    setCatalogData({});
  };

  return (
    <>
      <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>eSimple {country?.name ? "| " + country.name : ""}</title>
          </Helmet>
        </HelmetProvider>
      <div className="country-dropdown flex align-items-center justify-content-between">
        <div className="flex flex-column">
        <div className="region-select-section mb-4">
            <PR.Button 
              label={labels.LBL0208}
              className={"select-buttons " + (selectedDropdown === "Local" ? "active" : "")}
              onClick={() => onDropdownChange("Local")}
              />
            <PR.Button 
              label={labels.LBL0209}
              className={"select-buttons " + (selectedDropdown === "Regional" ? "active" : "")} 
              onClick={() => onDropdownChange("Regional")}
              />
            <PR.Button
                label={labels.LBL0211}
                className={"select-buttons " + (selectedDropdown === "Global" ? "active" : "")}
                value={global}
                onClick={() => onDropdownChange("Global")}
              />
          </div>
          {/* <span className="label-span">{t("home.iWantData2goIn")}</span> */}
          {
            selectedDropdown === "Local"
              ? <PR.Dropdown
              filter  
              value={localCountry}
              className={"choose-region-button active"}
              options={countries.filter(c => c.geog_type === "Local")}
              onChange={(e) => onCountryChange(e)}
              onHide={() => onCountryHide(country)}
              resetFilterOnHide
              optionLabel="name"
              placeholder={country?.geog_type === "Local" ? country?.name : labels.LBL0193}
            />
              : selectedDropdown === "Regional"
                ? <PR.Dropdown
                filter
                value={regionalCountry}
                className={ "choose-region-button active" }
                options={countries.filter(c => c.geog_type === "Regional")}
                onChange={(e) => onCountryChange(e)}
                onHide={() => onCountryHide(country)}
                resetFilterOnHide
                optionLabel="name"
                placeholder={country?.geog_type === "Regional" ? country?.name : labels.LBL0210}
              />
                : selectedDropdown === "Global" 
                  ? <PR.Dropdown
                    value={globalCountry}
                    className={"choose-region-button active"}
                    options={countries.filter(c => c.geog_type === "Global")}
                    optionLabel="name"
                    placeholder={labels.LBL0211}
                  />
                  : <PR.Dropdown
                  className={"choose-region-button active"}
                  placeholder={labels.LBL0186}
                />
          }
        </div>
        <div className="logo_icon">
          <PR.Image src={esimIcon} className="esim-icon" alt="eSIM Icon" />
        </div>
      </div>
      <PR.Dialog
        header={labels.LBL0016}
        className="bundle-dialog"
        visible={bundleInfoVisible}
        blockScroll={true}
        draggable={false}
        breakpoints={{ "960px": "85vw" }}
        style={{ width: "38vw" }}
        onHide={onHide}
      >
        <div className="data-item">
          <div className="data-item-header">
            <div className="grid grid-nogutter mt-2 align-items-center">
              <div className="col-12 md:col-9 lg:col-9">
                <h3>
                  <span className="vertical-align-super">
                    {catalogData.description}
                  </span>
                </h3>
              </div>
              <div className="col-12 md:col-3 lg:col-3 text-right">
                {catalogData.price && (
                  <h3 className="color-h3">{renderPrice(catalogData.price)}</h3>
                )}
              </div>
            </div>
          </div>
          <div className="data-item-content">
            <table className="w-12">
              <tbody>
                <tr>
                  <td>{labels.LBL0024}</td>
                  <td className="text-right">{setDataAmount(catalogData, labels)} {unlimitedPlanUsageAsterisk(catalogData?.dataAmountForDisplay, labels)}</td>
                </tr>
                <tr>
                  <td>{labels.LBL0025}</td>
                  <td className="text-right">
                    {setDataDuration(catalogData.duration)}
                  </td>
                </tr>
                {catalogData.speed && (
                  <tr>
                    <td>{labels.LBL0026}</td>
                    <td className="text-right">
                      {setDataSpeed(catalogData.speed)}
                    </td>
                  </tr>
                )}
                <tr
                  className={
                    getNetworks(catalogData.countries).length > 0
                      ? "network-tr"
                      : ""
                  }
                >
                  <td>{labels.LBL0028}</td>
                  {catalogData.geog_type === "Global" ||
                    catalogData.geog_type === "Regional" ? (
                    <td>
                      <span className="roamingData">
                        {labels.LBL0029} (
                        {catalogData.roamingEnabled &&
                          catalogData.roamingEnabled.length}{" "}
                        {labels.LBL0030})
                        <i
                          className="roaming-tooltip pi pi-info-circle info-btn"
                          title="View Roaming Countries"
                          onClick={() => setDisplayNetworks(true)}
                        ></i>
                        <PR.Dialog
                          header={labels.LBL0031}
                          className="roamingColumnsSection bundle-dialog"
                          visible={displayNetworks}
                          blockScroll={true}
                          draggable={false}
                          onHide={() => setDisplayNetworks(false)}
                          style={{ width: "60vw" }}
                          resizable={false} >
                          <div>
                            {catalogData.roamingEnabled &&
                              catalogData.roamingEnabled.map(
                                (column, columnIndex) => (
                                  <div key={columnIndex}>
                                    <p>
                                      <strong>
                                        {column.country && column.country.name
                                          ? column.country.name.replace(" (Special Administrative Region of China)", "")
                                          : ""}
                                      </strong>
                                    </p>
                                    <ul key={columnIndex}>
                                      {column.networks &&
                                        column.networks.map(
                                          (item, itemIndex) => (
                                            <li
                                              key={itemIndex}
                                              className="mb-2"
                                            >
                                              {item.name} (
                                              {item.speeds &&
                                                item.speeds.join(",")}
                                              )
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                )
                              )}
                          </div>
                        </PR.Dialog>
                      </span>
                    </td>
                  ) : (
                    <td>{setNetworks(catalogData.countries)}</td>
                  )}
                </tr>
                <tr className="last-row">
                  <td className="text-left">
                    <PR.Button
                      label={labels.LBL0032}
                      className="buynow-btn"
                      onClick={() => {
                        catalogPurchase(catalogData);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PR.Dialog>
      <PR.Dialog
        visible={checkoutFormState}
        className="forms-dialog payment-dialog"
        blockScroll={true}
        draggable={false}
        style={{ width: "75%" }}
        onHide={() => setCheckoutFormState(false)}
      >
        <MakePayment setCheckoutFormState={setCheckoutFormState} />
      </PR.Dialog>
      {loading ? (
        BundleListLoading()
      ) : catalogs && catalogs.length > 0 ? (
        <PR.Carousel
          value={catalogs}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
        />
      ) : (
         (
          <div className="no-catalog-data">
            <PR.Image src={formsLogo} alt="logo" />
            <p>{labels.LBL0212}</p>
          </div>
        )
      )}
    </>
  );
};

export default BundlesList;
