import { createSlice } from '@reduxjs/toolkit';

const getscrollToBundle = localStorage.getItem('SCROLL-TO-BUNDLES');
const scrollToBundle = (getscrollToBundle === 'undefined' || getscrollToBundle === "") ? JSON.stringify(getscrollToBundle) : (getscrollToBundle !== null) ? JSON.parse(getscrollToBundle) : false;

const scrollToBundlesState = {
    scrollToBundlesState:scrollToBundle
}

export const scrollToBundles = createSlice({
    name: 'scrillToBundles',
    initialState: scrollToBundlesState,
    reducers: {
        setScrollToBundles(state, action) {
            state.scrollToBundlesState = action.payload;
            localStorage.setItem("SCROLL-TO-BUNDLES", JSON.stringify(action.payload));
        },
        removeScrollToBundles(state) {
            state.scrollToBundlesState = false;
            localStorage.removeItem('SCROLL-TO-BUNDLES');
        }
    },
})

export const scrollToBundlesActions = scrollToBundles.actions;
export default scrollToBundles.reducer;
