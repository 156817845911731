import React from "react";
import { useState, useEffect } from 'react';
import "../terms-and-conditions/TermsAndConditions.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import termsAndConditions_nl from "../../assets/data/termsAndConditions_nl.json"
import termsAndConditions_en from "../../assets/data/termsAndConditions_en.json"
import { useToast } from '../../context/ToastContext'

const TermsAndConditions = () => {
    const { i18n } = useTranslation();
    const { labels } = useToast();
    const [termsData, setTermsData] = useState({ heading: '', subHeading: '', description: '', sections: [] });

    useEffect(() => {
        const defaultLang = i18n.language;
        if (defaultLang === "nl") {
            setTermsData(termsAndConditions_nl);
        } else if (defaultLang === "en") {
            setTermsData(termsAndConditions_en)
        }
      }, [i18n.language]);

      return (
        <HelmetProvider> 
            <Helmet>
                <meta charSet="utf-8" />
                <title>esimple | {labels.LBL0206}</title>
            </Helmet>

        {Array.isArray(termsData) ? (
            termsData.map((term, termIndex) => (
                <div key={termIndex}>
                {/* Header Section */}
                <SubHeader 
                    header={term.heading} 
                    subText={term.subHeading} 
                />
                <section className="about-blocks-section tc-block">
                    <div className="terms-and-conditions-section">
                    <p dangerouslySetInnerHTML={{ __html: term.description }} />
                    {term.sections?.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="terms-section">
                        <h2>
                            <span>{section.title.num}</span> {section.title.text}
                        </h2>
                        <ul className="sub-number-ol">
                            {section.content?.map((item, itemIndex) => (
                            <li key={itemIndex}>
                                <div className="flex">
                                    <span className="numbers">{item.num}</span> <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                </div>
                                {item.list && (
                                <ol>
                                    {item.list.map((listItem, listIndex) => (
                                    <li key={listIndex}>
                                        <div className="flex">
                                            <span className="numbers">{listItem.num}</span> <span>{listItem.text}</span>
                                        </div>
                                    </li>
                                    ))}
                                </ol>
                                )}
                            </li>
                            ))}
                        </ul>
                        {section.Account && (
                            <p>
                            <b>"Account":</b> {section.Account}
                            </p>
                        )}
                        {section.Content ? (
                            <p>
                            <b>"Content":</b> {section.Content}
                            </p>
                        ) : section.Inhoud ? (
                            <p>
                            <b>"Inhoud":</b> {section.Inhoud}
                            </p>
                        ) : null}
                        {section.WeUsOur && (
                            <p>
                            <b>"We/Us/Our":</b> <a href={section.WeUsOur.url}>{section.WeUsOur.text}</a>
                            </p>
                        )}
                        {section.SubDescription && <p>{section.SubDescription}</p>}
                        {section.SubText && <p>{section.SubText}</p>}
                        </div>
                    ))}
                    </div>
                </section>
                </div>
            ))
            ) : (
            <p>No terms and conditions available or data is not in the expected format.</p>
            )}

         {/* Support Mails Section */}
            <section className="inner-header-section aboutus-header-section support-section">
                <SupportMailsSection/>
            </section>
        </HelmetProvider>
    );

};

export default TermsAndConditions;
