import React from 'react';
import { useToast } from '../../context/ToastContext';

const PageLoading = () => {
    const { labels } = useToast()
    return (
        <>
            <div className="overlay">
                <div className="loading-block">
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>
                    <p>{labels.LBL0069}</p>
                </div>
            </div>
        </>
    );
}
 
export default PageLoading;