import React, { useEffect } from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/about-us/AboutUs';
import MyAccount from './pages/my-account/MyAccount';
import RedirectVerifyEmail from './components/RedirectVerifyEmail';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Faq from './pages/faq/Faq';
import Maintenance from './pages/maintenance/Maintenance';
import CompatibleDevices from './pages/compatible-devices/CompatibleDevices';
import { useSelector } from 'react-redux';
import ScrollToTop from './components/layout/ScrollToTop';
import Footer from './components/layout/Footer';
import BundleDetails from './pages/bundle-details/BundleDetails';
import Paypal from './pages/web-paypal/Paypal';
import Affiliate from './pages/affiliate/Affiliate';
import CountryPage from './pages/country-page/CountryPage';
import PaymentSummary from './components/payment/PaymentSummary';
import { useTranslation } from 'react-i18next';
import CMS from './CMS';

function App() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;
  const { i18n } = useTranslation();
  const routeSplit = window.location.pathname?.toLowerCase().split('/');
  const routeLang = routeSplit?.length >= 2 ? routeSplit[1] : '';
  useEffect(() => {
    const langs = ['nl','en'];
    const setLang = langs.includes(routeLang) ? routeLang : i18n.language;
    i18n.changeLanguage(setLang);
}, [i18n,routeLang])
  
const CommonRoutes = () => [
  <Route key='about-us' path='about-us' element={<AboutUs />} />,
  <Route key='contact' path='contact' element={<Contact />} />,
  <Route key='terms-and-conditions' path='terms-and-conditions' element={<TermsAndConditions />} />,
  <Route key='cookie-policy' path='cookie-policy' element={<CookiePolicy />} />,
  <Route key='privacy-policy' path='privacy-policy' element={<PrivacyPolicy />} />,
  <Route key='faq' path='faq' element={<Faq />} />,
  <Route key='compatible-devices' path='compatible-devices' element={<CompatibleDevices />} />,
  <Route key='affiliate' path='affiliate' element={<Affiliate />} />,
  <Route key='country' path=':country' element={<CountryPage />} />
];
  return (
    <>
       <Router>
        <CMS>
        <ScrollToTop />
        <Routes>
          <>
            {isUnderMaintenance ? (
              <Route index element={<Maintenance />} />
            ) : (
              <>
                <Route index exact path="/" element={<Home />} />
                <Route path="/en" element={<Home />} />
                <Route path="/nl" element={<Home />} />
                <Route path="en" element={<Layout />}>
                  {CommonRoutes()}
                </Route>
                <Route path="nl" element={<Layout />}>
                  {CommonRoutes()}
                </Route>
                {CommonRoutes()}
                <Route path="my-account" element={isLoggedIn ? <MyAccount /> : <Navigate to="/" />} />
                <Route path="update-email" element={<RedirectVerifyEmail />} />
                <Route path="verify-email" element={<RedirectVerifyEmail />} />
                <Route path="reset-password" element={<RedirectVerifyEmail />} />
                <Route path="bundles/:id" element={<BundleDetails />} />
                <Route path='paypal' element={<Paypal />} />
                <Route path="paymentresponse" element={<PaymentSummary />} />
                <Route path="page-not-found" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
              </>
            )}
          </>
        </Routes>
        {window.location.pathname !== "/paypal" && <Footer />}
        </CMS>
      </Router>
     </>
  );
}
const Layout = () => {
  return (
      <Outlet />
  );
};

export default App;