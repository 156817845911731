import React from "react";
import "../privacy-policy/PrivacyPolicy.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { useToast } from "../../context/ToastContext";

const CookiePolicy = () => {
    const {labels, globalConfig, general} = useToast()

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{general.GEN0001} | {labels.LBL0128}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels.LBL0128} subText={labels.LBL0129} />

      <section className="about-blocks-section tc-block">
        <div className="privacy-policy-section terms-and-conditions-section">
            <p>This website | <a href={globalConfig.domainUrl} rel="canonical">{globalConfig.domainUrl}</a> ("Our Site") uses Cookies and similar technologies in order to distinguish you from other users. By using Cookies, We are able to provide you with a better experience and to improve Our Site by better understanding how you use it. Please read this Cookie Policy carefully and ensure that you understand it. Your acceptance of Our Cookie Policy is deemed to occur if you continue using Our Site and/or if you click to accept Our Cookie Policy. If you do not agree to Our Cookie Policy, please stop using Our Site immediately. These Cookie Policies are in the English language only.</p>
            <ol className="sub-number-ol">
                <li>
                    <span className="heading">Definitions and Interpretation</span>
                    <ol>
                        <li>This Cookie Policy, unless the context otherwise requires, the following expressions have the following meanings:</li>
                    </ol>
                    <p><b>"Cookie"</b>:  means a small file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site;</p>
                    <p><b>"Cookie Law"</b>: means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 and of EU Regulation 2016/679 General Data Protection Regulation or any successor legislation;</p>
                    <p><b>"Personal Data"</b>: means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data, as defined by the Data Protection Act 2018 and EU Regulation 2016/679 General Data Protection Regulation or any successor legislation ("GDPR"); and</p>
                    <p><b>"We/Us/Our"</b>: <a href={globalConfig.domainUrl} rel="canonical">{globalConfig.domainUrl}</a> </p>
                </li>
                <li>
                    <span className="heading">Information About Us</span>
                    <p>Our Site is owned and operated by Esimple, a sole proprietorship registered in the Netherlands under company number <b>80427413</b>, whose headquarters are located at <b>46, Van Loostraat, The Hague 2582XE, The Netherlands</b>.</p>
                </li>
                <li>
                    <span className="heading">How does Our Site use Cookies?</span>
                    <ol>
                        <li>Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and/or services. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.</li>
                        <li>By using Our Site, you may also receive certain third party Cookies on your computer or device. Third party Cookies are those placed by websites, services, and/or parties other than Us. For more details, please refer to section 4 below.</li>
                        <li>All Cookies used by and on Our Site are used in accordance with current Cookie Law. We may use some or all of the following types of Cookie:
                            <ol>
                                <li>
                                    <p><b>Strictly Necessary Cookies</b></p>
                                    A Cookie falls into this category if it is essential to the operation of Our Site, supporting functions such as logging in, your shopping basket, and payment transactions.
                                </li>
                                <li>
                                    <p><b>Analytics Cookies</b></p>
                                    It is important for Us to understand how you use Our Site, for example, how efficiently you are able to navigate around it, and what features you use. Analytics Cookies enable us to gather this information, helping Us to improve Our Site and your experience of it.
                                </li>
                                <li>
                                    <p><b>Functionality Cookies</b></p>
                                    Functionality Cookies enable Us to provide additional functions to you on Our Site such as personalisation and remembering your saved preferences. Some functionality Cookies may also be strictly necessary Cookies, but not all necessarily fall into that category.
                                </li>
                                <li>
                                    <p><b>Targeting Cookies</b></p>
                                    It is important for Us to know when and how often you visit Our Site, and which parts of it you have used (including which pages you have visited and which links you have visited). As with analytics Cookies, this information helps us to better understand you and, in turn, to make Our Site and advertising more relevant to your interests.
                                </li>
                                <li>
                                    <p><b>Third Party Cookies</b></p>
                                    Third party Cookies are not placed by Us; instead, they are placed by third parties that provide services to Us and/or to you. Third party Cookies may be used by advertising services to serve up tailored advertising to you on Our Site, or by third parties providing analytics services to Us (these Cookies will work in the same way as analytics Cookies described above).
                                </li>
                                <li>
                                    <p><b>Persistent Cookies</b></p>
                                    Any of the above types of Cookie may be a persistent Cookie. Persistent Cookies are those which remain on your computer or device for a predetermined period and are activated each time you visit Our Site.
                                </li>
                                <li>
                                    <p><b>Session Cookies</b></p>
                                    Any of the above types of Cookie may be a session Cookie. Session Cookies are temporary and only remain on your computer or device from the point at which you visit Our Site until you close your browser. Session Cookies are deleted when you close your browser.
                                </li>
                            </ol>
                        </li>
                        <li>Cookies on Our Site are not permanent and will expire as indicated in the table below.</li>
                        <li>For more details of the personal data that We collect and use, the measures we have in place to protect personal data, your legal rights, and our legal obligations, please refer to our <a href="https://esimple.nl/privacy-policy" rel="canonical">Privacy Policy</a>.</li>
                        <li>For more specific details of the Cookies that We use, please refer to the table below.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">What Cookies does Our Site use?</span>
                    <ol>
                        <li>The following first party Cookies may be placed on your computer or device:
                            <div className='table-responsive'>
                                <table className="content-table">
                                    <thead>
                                        <tr>
                                            <th>Name of Cookie</th>
                                            <th>Purpose & Type</th>
                                            <th>Strictly Necessary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>cookieconsent_status</td>
                                            <td>Show message regarding use of cookies for first time users</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>externalID</td>
                                            <td>Assist guest users to add products to cart</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Local storage</td>
                                            <td>Store user information on your computer</td>
                                            <td>Yes</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>The following third party Cookies may be placed on your computer or device:
                            <div className='table-responsive'>
                                <table className="content-table">
                                    <thead>
                                        <tr>
                                            <th>Name of Cookie</th>
                                            <th>Purpose & Type</th>
                                            <th>Provider</th>
                                            <th>Strictly Necessary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>stripe_mid</td>
                                            <td>Stripe uses this cookie to provide fraud prevention</td>
                                            <td><a href="https://stripe.com/en-in/privacy" target='_blank' rel="noreferrer">Stripe</a></td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>stripe_sid</td>
                                            <td>Stripe uses this cookie to provide fraud prevention</td>
                                            <td><a href="https://stripe.com/en-in/privacy" target='_blank' rel="noreferrer">Stripe</a></td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>multiple paypal cookies</td>
                                            <td>paypal uses cookies & web beacons to personalize user experience and store relevant information in your browser or device</td>
                                            <td><a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target='_blank' rel="noreferrer">Paypal</a></td>
                                            <td>No</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>Our Site uses analytics services provided by Plausible. Website analytics refers to a set of tools used to collect and analyze anonymous usage information, enabling Us to better understand how Our Site is used. This, in turn, enables Us to improve Our Site and the products and/or services offered through it. Whilst Our use of them does not pose any risk to your privacy or your safe use of Our Site, it does enable Us to continually improve Our Site, making it a better and more useful experience for you.</li>
                        <li>The analytics service(s) used by Our Site does not use Cookies to gather the required information.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Consent and Control?</span>
                    <ol>
                        <li>Before Cookies are placed on your computer or device, you will be shown a message informing you of the use of Cookies. By using our site, you are granting consent to the use of these Cookies. Our Site may not function fully or as intended if your browser does not accept these Cookies.</li>
                        <li>In addition to the controls that We provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third party Cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</li>
                        <li>The links below provide instructions on how to control Cookies in all mainstream browsers:
                            <ol>
                                <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target='_blank' rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=en-GB</a></li>
                                <li>Microsoft Internet Explorer: <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target='_blank' rel="noreferrer">https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc</a></li>
                                <li>Microsoft Edge: <a href="https://support.microsoft.com/en-gb/microsoft-edge" target='_blank' rel="noreferrer">https://support.microsoft.com/en-gb/microsoft-edge</a> (Please note that there are no specific instructions at this time, but Microsoft support will be able to assist)</li>
                                <li>GSafari (macOS): <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target='_blank' rel="noreferrer">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a></li>
                                <li>Safari (iOS): <a href="https://support.apple.com/en-gb/HT201265" target='_blank' rel="noreferrer">https://support.apple.com/en-gb/HT201265</a></li>
                                <li>Mozilla Firefox: <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target='_blank' rel="noreferrer">https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US</a></li>
                                <li>Android: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en" target='_blank' rel="noreferrer">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en</a> (Please refer to your device's documentation for manufacturers own browsers)</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Changes to this Cookie Policy</span>
                    <ol>
                        <li>We may alter this Cookie Policy at any time. Any such changes will become binding on you on your first use of Our Site after the changes have been made. You are therefore advised to check this page from time to time.</li>
                        <li>In the event of any conflict between the current version of this Cookie Policy and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">Further Information</span>
                    <ol>
                        <li>If you would like to know more about how we use cookies, please contact us at <a href={`mailto:${general.GEN0008}`}  target='_top'>{general.GEN0008}</a></li>
                        <li>For more information about privacy, data protection and our terms and conditions, please visit the following:
                            <ol>
                                <li><a href="/privacy-policy" rel="canonical">Privacy Policy</a></li>
                                <li><a href="/terms-and-conditions" rel="canonical">Terms and Conditions</a></li>
                                <li><a href="/faq" rel="canonical">FAQ</a></li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default CookiePolicy;
