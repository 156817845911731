import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PR from '../../prime-modules'
import AppleSignin from 'react-apple-signin-auth';
import { appleLogin } from '../../services/api';
import { authActions } from '../../store/auth';
import { esimIccidActions } from '../../store/esim';
import PageLoading from "../layout/PageLoading";
import { applePay } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getAllCountries } from "../../genericFunctions/GetAllCountries";
import { useToast } from "../../context/ToastContext";


const AppleLoginComponent = ({displayName, renderFormState}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef();
    const { showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const appleResponse = res => {
        setLoading(true);
            if (!res.error) {
                const getResponse = response => {
                    if (response.result === "SUCCESS") {
                        setLoading(false);
                        const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
                        getAllCountries(showToast, dispatch, callingCode, '');
                        dispatch(authActions.onLogin(response.data));
                        dispatch(esimIccidActions.deleteEsim());
                        navigate("/");
                        renderFormState(false, "");
                    } else if (response.result === "FAILED") {
                        setLoading(false)
                        const error = response.error;
                        showToast(error.severity, error.errorMsg);
                    }
                }
                appleLogin(res, headers, dispatch, getResponse)
            } else {
                setLoading(false)
                showToast("error", "USRLGN-06");
            }
    };

    return (
        <>
            <AppleSignin
                authOptions={{
                    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                    redirectURI: process.env.REACT_APP_APPLE_CALLBACK_URL,
                    scope: 'email name',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                }}
                onSuccess={appleResponse}
                render={props => {
                    return <div target="_blank" {...props} className="social-button">
                                <PR.Image className="applePay" src={applePay}/>
                                <span className="appleBtnText">{displayName}</span>
                            </div>
                }}
            />
            
            {loading && 
                <PageLoading />
            }
            <PR.Toast ref={toast}/>
        </>
    );
}
 
export default AppleLoginComponent;