import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import "../assets/scss/main.scss";
import { useState } from 'react';
import { envConfig } from '../constants';
import { useSelector } from 'react-redux';
import { useToast } from '../context/ToastContext';

const CustomPhoneNumber = ({ formik, setPhoneValue, id, phoneError }) => {
    const getIPCountry = useSelector((state) => state.country.IPCountry).toLowerCase();
    const getLoggedInCountry = useSelector((state) => state.allCountries.phoneCountry);
    const userPhoneCountry = getLoggedInCountry?.iso ? getLoggedInCountry?.iso.toLowerCase() : '';
    const getPhoneDefaultCountry = envConfig.REACT_APP_DEFAULT_PHONE_COUNTRY;
    const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
    const defaultIso = userPhoneCountry ? userPhoneCountry : getPhoneDefaultCountry ? getPhoneDefaultCountry : getIPCountry;

    const [iso, setIso] = useState(defaultIso);
    const { labels } = useToast();

    const removeLeadingZero = (phoneValue) => {
        return phoneValue.replace(/^0+/, '');
    };

    return (
        <span className="p-input-icon-right custom-phoneno-field">
            <PhoneInput
                inputProps={{
                    name: 'phone',
                    required: true,
                    id: id
                }}
                countryCodeEditable={false}
                autoFormat={true}
                enableSearch
                searchPlaceholder='Search'
                onBlur={formik.handleBlur}
                placeholder={labels.LBL0015}
                country={iso}
                value={formik.values.phone || getDefaultCallingCode}
                onChange={(value, data) => {
                    let phoneValue = `+${value}`.replace(`+${data.dialCode}`, "");
                    phoneValue = removeLeadingZero(phoneValue);
                    formik.setFieldValue("phone", `+${data.dialCode}${phoneValue}`);
                    setPhoneValue(phoneValue);
                    formik.setFieldValue("callingCode", `+${data.dialCode}`);
                }
                }
                isValid={(country) => {
                    if (country === 0) {
                        setIso(getIPCountry);
                    }
                    return true;
                }}
                style={{ 'width': '100%' }}
            >
            </PhoneInput>
            {(formik?.touched?.phone && phoneError && <div className='error-message'>{phoneError}</div>)}
        </span>
    )
}

export default CustomPhoneNumber;