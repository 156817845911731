import { createSlice } from '@reduxjs/toolkit';

const getinitialEsimIccid = localStorage.getItem('ICCID');
const initialEsimIccid = (getinitialEsimIccid !== 'undefined' && getinitialEsimIccid !== null && getinitialEsimIccid !== "") ? JSON.parse(getinitialEsimIccid) : {esimIccid: '', tag: '', providerCode: 0};

export const esimIccidSlice = createSlice({
    name: 'esimIccid',
    initialState: { ...initialEsimIccid, eSIMS: [], iseSIMsLoading: false},
    reducers: {
        setEsim(state, action) {
            state.esimIccid = action.payload.esimIccid;
            state.tag = action.payload.tag;
            state.providerCode = action.payload?.providerCode;
            localStorage.setItem("ICCID", JSON.stringify(action.payload));
        },
        deleteEsim(state) {
            state.esimIccid = '';
            state.tag = '';
            state.providerCode = 0;
            localStorage.removeItem('ICCID');
        },
        storeAlleSIMS(state, action) {
            state.eSIMS = action.payload;
        },
        reseteSIMS(state) {
            state.eSIMS = [];
            state.iseSIMsLoading = false;
        },
        storeLoading(state, action) {
            state.iseSIMsLoading = action.payload;
        }
    },
})

export const esimIccidActions = esimIccidSlice.actions;
export default esimIccidSlice.reducer;
