import { Link } from "react-router-dom";
import { useToast } from "../context/ToastContext";

const SupportMailsSection = () => {
    const { labels, globalConfig,  general } = useToast();
    return (
    <section className="support-mails-section">
    <div className="custom-container">
        <div className="grid grid-nogutter contact-info-block">
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels.LBL0177}</h3>
            <p>
                {labels.LBL0176} <br />
                {labels.LBL0178}
            </p>
            <Link to={`mailto:${globalConfig.supportEmail}`}>
                {globalConfig.supportEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels.LBL0171}</h3>
            <p>
                {labels.LBL0176} <br />
                {labels.LBL0178}
            </p>
            <Link to={`mailto:${globalConfig.salesEmail}`}>
                {globalConfig.salesEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{labels.LBL0180}</h3>
            <p>
            {labels.LBL0183}-{labels.LBL0184} {labels.LBL0181} 8am {labels.LBL0182} <br />
                5pm.
            </p>
            <span><Link to={`tel:${general.GEN0007}`}>{general.GEN0007}</Link> </span>
            </div>
        </div>
    </div>
    </section>
  );
};

export default SupportMailsSection;
