import React, { useMemo, useState } from "react";
import "../contact/Contact.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeaderMenu from "../../components/layout/HeaderMenu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contact as contactAPI } from "../../services/api";
import {
  handleFirstNameChange,
  handleLastNameChange,
  trimFormData,
  displayDefaultPhone
} from "../../utils/reuse";
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { getPhoneObj } from "../../genericFunctions/GetAllCountries";
import { useToast } from "../../context/ToastContext";
import getAssets from '../../assets/data/brandingAssets.json';

const Contact = () => {
  const dispatch = useDispatch();
  const { showToast, labels, validations, general, isBase64Image, contact } = useToast()
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.sessionData);
  const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
  const [phoneValue, setPhoneValue] = useState("");
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);

  const formInitialValues = {
    firstName: isAuth ? userData?.firstName : "",
    lastName: isAuth ? userData?.lastName : "",
    email: isAuth ? userData?.email : "",
    phone: displayDefaultPhone(isAuth, userData, getDefaultCallingCode),
    body: "",
    type: "contact",
  };

  const validationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(validations.VLD0007)
        .min(3, validations.VLD0008)
        .max(20, validations.VLD0008),
      lastName: Yup.string()
        .trim()
        .min(3, validations.VLD0010)
        .max(20, validations.VLD0010),
      email: Yup.string()
        .email( validations.VLD0006)
        .required( validations.VLD0005),
      body: Yup.string().trim().required(validations.VLD0015),
    });
  };

  const handleSubmit = (formData) => {
    console.log(formData)
    const trimmedFormData = trimFormData(formData);

    const requestObj = { ...trimmedFormData };
    requestObj.phone = getPhoneObj(phoneValue, isAuth, userData, formData);
    delete requestObj.callingCode;
    const contactResponse = (response) => {
      if (response.result === "SUCCESS") {
        formik.resetForm();
        setPhoneValue("");
        showToast("success", "CNTMSG-04")
        setTimeout(() => {
        }, [3000]);
      } else {
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    };
    contactAPI(requestObj, headers, dispatch, contactResponse);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isSubmitDisabled = () => {
    return !(
      formik.values.firstName &&
      formik.values.email &&
      formik.values.body &&
      formik.isValid
    );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${labels.LBL0125}`}</title>
      </Helmet>

      {/* Header Section */}
      <section className="inner-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h1>{contact.CON0001}</h1>
            <p>{contact.CON0002}</p>
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="grid header-text">
          <div className="col-12">
            <div className="form-box">
              <form
                className="contact_form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="firstName">
                        {labels.LBL0001}
                      </label>
                      <span className="p-input-icon-right">
                        <PR.InputText
                          id="firstName"
                          name="firstName"
                          placeholder={labels.LBL0001}
                          className="w-12"
                          keyfilter={"alphanum"}
                          value={formik.values.firstName}
                          onChange={(e) => handleFirstNameChange(e, formik)}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.errors.firstName && formik.touched.firstName ? (
                          <div className="error-message">
                            {(formik.errors.firstName)}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="lastName">
                        {labels.LBL0002}
                      </label>
                      <PR.InputText
                        id="lastName"
                        name="lastName"
                        placeholder={labels.LBL0003}
                        className="w-12"
                        keyfilter={"alphanum"}
                        value={formik.values.lastName}
                        onChange={(e) => handleLastNameChange(e, formik)}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.errors.lastName && formik.touched.lastName ? (
                        <div className="error-message">
                          {(formik.errors.lastName)}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="email">{labels.LBL0010}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText
                          id="email"
                          name="email"
                          placeholder={labels.LBL0010}
                          className="w-12"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <div className="error-message">
                            {(formik.errors.email)}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="phone">{labels.LBL0015}</label>
                      <CustomPhoneNumber
                        formik={formik}
                        setPhoneValue={setPhoneValue}
                        id="phone"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="body">
                        {labels.LBL0012}
                      </label>
                      <span className="p-input-icon-right">
                        <PR.InputTextarea
                          rows={4}
                          cols={30}
                          maxLength={500}
                          autoResize
                          className="w-12"
                          id="body"
                          name="body"
                          placeholder={labels.LBL0127}
                          value={formik.values.body}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.errors.body && formik.touched.body ? (
                          <div className="error-message">
                            {(formik.errors.body)}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <p>{labels.LBL0126}</p>
                  </div>
                  <PR.Button
                    className="contact-button p-button-rounded"
                    type="submit"
                    label={labels.LBL0014}
                    disabled={isSubmitDisabled()}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="grid mt-6 grid-nogutter text-center">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="contact-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(contact.CON0003) ? `data:image/png;base64,${contact.CON0003}`: getAssets.contact.mail}
                alt="Benefits"
              />
              <h3>{contact.CON0004}</h3>
              <p>{contact.CON0005}</p>
              <Link to={`mailto:${contact.CON0006}`}>
                {contact.CON0006}
              </Link>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="contact-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(contact.CON0007) ? `data:image/png;base64,${contact.CON0007}`: getAssets.contact.whatsapp}
                alt="Benefits"
                height={110}
              />
              <h3>{contact.CON0008}</h3>
              <p>{contact.CON0009}</p>
              <p>
                <Link
                  to={"https://wa.me/message/QYNOEY3EZUZFF1"}
                  className="faq-link"
                >
                  {contact.CON0010}
                </Link>
              </p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="contact-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(contact.CON0011) ? `data:image/png;base64,${contact.CON0011}`: getAssets.contact.support}
                alt="Benefits"
              />
               <h3>{contact.CON0012}</h3>
              <p>{contact.CON0013}</p>
              <Link className="faq-link" to={`tel:${contact.CON0014}`}>{contact.CON0014}</Link>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Contact;
