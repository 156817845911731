import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import * as PR from "../prime-modules/index"
import { globalConfigData } from '../constants';
import { useSelector } from 'react-redux';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const catalogIds = useSelector(state => state.catalogIds.messageCatalog);
  const {labels, validations, myAccount, home, general, aboutUs, contact, header, partner, affiliate, themeColors } = useSelector(state => state.catalogIds);
  const toastKeys = useMemo(() => ({
    "success": "LBL0143",
    "error": "LBL0144",
    "info": "LBL0145"
  }), []);

  const globalConfig = globalConfigData;
  globalConfig.phoneNumber = general.GEN0007;
  globalConfig.supportEmail = general.GEN0008;
  globalConfig.salesEmail = general.GEN0008;

  
  const showToast  = useCallback((severity, messageId, key ='errorMsg') => {
    const id = toastKeys[severity];
    if (toast.current) {
        toast.current.show({
            life: globalConfig.toastDisplayTime,
            severity: severity,
            summary: labels[id],
            detail: catalogIds[messageId] ? catalogIds[messageId] :  messageId,
        });
    }
  }, [catalogIds, globalConfig.toastDisplayTime, labels, toastKeys]);
  const showMessage = (messageId, key ='errorMsg') => {
   return catalogIds[messageId] ? catalogIds[messageId] :  messageId;
  };
  const isBase64Image = (image) => {
    if(image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA')){
      return true
    }
    return false
  }

  return (
    <ToastContext.Provider value={{showToast, showMessage, isBase64Image, labels, validations, account:myAccount , home, general, aboutus: aboutUs, contact, header, partner, affiliate, themeColors, globalConfig}}>
      <PR.Toast ref={toast} position="top-right" baseZIndex={9999} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};