import React, { useEffect, useState } from 'react';
import * as PR from '../../prime-modules/index';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const SelectLanguageDropDown = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routeName = location.pathname?.toLowerCase(); // or derive a name from pathname
    const routeSplit = routeName?.split('/');
    const routeLang = routeSplit?.length >= 2 ? routeSplit[1] : '';
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(routeLang === 'nl' ? routeLang  : routeLang === 'en' ? routeLang : i18n.language);
    const languages = [
        { label: 'English', value: 'en' },
        { label: 'Dutch' , value: 'nl' }
    ];

    const changeLanguage = (lang) => {
        setLanguage(lang.value);
        i18n.changeLanguage(lang.value);
        const routeSplit = routeName.split('/');
        if(routeSplit.length === 3){
            navigate(`/${lang.value}/${routeSplit[2]}`);
        }
        if(routeSplit.length === 2 && routeSplit[1]){
            const navigatRouteName = routeSplit[1] === 'en' || routeSplit[1] === 'nl' ? lang.value : routeSplit[1];
            navigate(`/${navigatRouteName}`);
        }
        
    }
    useEffect(() => {
        const defaultLang = language.split('-')[0];
        i18n.changeLanguage(defaultLang);
        setLanguage(defaultLang);
    }, [i18n, language])

    return (
        <PR.Dropdown
            value={language}
            options={languages}
            optionLabel="label"
            optionValue="value"
            onChange={changeLanguage}
            className='new-1'
        />
    );
}

export default SelectLanguageDropDown;